import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Ant Design toggling dynamically custom theme",
  "date": "2020-08-02T00:00:00.000Z"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hey there who are using ant design, If you wanna add one more theme to your application, this information can be useful.
When I faced this task I think that it would be easy solution because antd has a configuration with variables and support it in dynamically should be ease. But I forgot for the .less and live compilation for the product. And as an additional, I'm using a create react app, that hasn't access to the webpack configuration from the box.`}</p>
    <h3>{`Tools`}</h3>
    <p>{`During some research and googling, was find several examples and articles that already solving this problem.`}</p>
    <p>{`Most common solution that I've met its npm module: `}<a parentName="p" {...{
        "href": "https://github.com/mzohaibqc/antd-theme-webpack-plugin"
      }}>{`https://github.com/mzohaibqc/antd-theme-webpack-plugin`}</a></p>
    <p>{`Here is one alternative package: `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/antd-theme-generator"
      }}>{`https://www.npmjs.com/package/antd-theme-generator`}</a>{` , I haven't tried antd-theme-generator, who wants to try alternative here is a article with example how to configure: `}<a parentName="p" {...{
        "href": "https://annacoding.com/article/gVcF6qCZkfnM3JOnPxBqx/Change-Ant-Design-Theme-dynamically-in-React-Create-React-App"
      }}>{`https://annacoding.com/article/gVcF6qCZkfnM3JOnPxBqx/Change-Ant-Design-Theme-dynamically-in-React-Create-React-App`}</a></p>
    <p>{`And to implement both of these solutions with Create react app we need, add additional webpack configuration module `}<inlineCode parentName="p">{`customize-cra`}</inlineCode>{`.`}</p>
    <h3>{`Solution`}</h3>
    <p>{`Main packages that I'm using:`}</p>
    <ol>
      <li parentName="ol">{`antd v3 (with `}<strong parentName="li">{`v4`}</strong>{` works the same)`}</li>
      <li parentName="ol">{`customize-cra`}</li>
      <li parentName="ol">{`create-react-app`}</li>
      <li parentName="ol">{`antd-theme-webpack-plugin`}</li>
    </ol>
    <p><strong parentName="p">{`Project Configuration`}</strong></p>
    <p>{`To our root directory we are adding file `}<inlineCode parentName="p">{`config-overrides.js`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`//config-overrides.js

const path = require("path");
const {
  override,
  fixBabelImports,
  addLessLoader,
  addWebpackPlugin,
} = require("customize-cra");
const AntDesignThemePlugin = require("antd-theme-webpack-plugin");

const options = {
  stylesDir: path.join(__dirname, "./src/antd-custom-style"),
  antDir: path.join(__dirname, "./node_modules/antd"),
  varFile: path.join(__dirname, "./src/antd-custom-style/vars.less"),
  // list of variables that will be listening
  themeVariables: ["@primary-color", "@body-background"],
  generateOnce: false, // generate color.less on each compilation
};

module.exports = override(
  fixBabelImports("import", {
    libraryName: "antd",
    libraryDirectory: "es",
    style: true,
  }),

  addWebpackPlugin(new AntDesignThemePlugin(options)),

  addLessLoader({
    javascriptEnabled: true,
  })
);
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`themeVariables: `}{`['@primary-color', '@body-background']`}{` depends on our example application will be listening just these two variables`}</p>
    </blockquote>
    <p>{`You can generate a list of all antd variables and paste it to the `}<inlineCode parentName="p">{`themeVariables`}</inlineCode>{` field, here is code implementation example from antd-theme-webpack-plugin : `}<a parentName="p" {...{
        "href": "https://github.com/mzohaibqc/antd-theme-webpack-plugin/blob/ebc9eb92c9ea7cbe3e703470bfc9ede131a7f2cc/examples/customize-cra/config-overrides.js#L21"
      }}>{`https://github.com/mzohaibqc/antd-theme-webpack-plugin/blob/ebc9eb92c9ea7cbe3e703470bfc9ede131a7f2cc/examples/customize-cra/config-overrides.js#L21`}</a></p>
    <p>{`Main fields that should be configured depending on your project `}<inlineCode parentName="p">{`varFile`}</inlineCode>{`, `}<inlineCode parentName="p">{`stylesDir`}</inlineCode>{`, `}<inlineCode parentName="p">{`themeVariables`}</inlineCode>{`, everything else it's boilerplate.`}</p>
    <p><strong parentName="p">{`Add toggler inside the Component`}</strong></p>
    <p>{`To any of your component add this two button, two trigger theme dynamic`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<button
  type="button"
  onClick={() => {
    window.less.modifyVars({
      '@primary-color': 'red',
      '@body-background': 'yellow',
    });
  }}
>
  Red Theme
</button>
<button
  type="button"
  onClick={() => {
    window.less.modifyVars({
      '@primary-color': 'blue',
      '@body-background': 'purple',
    });
  }}
>
  Blue Theme
</button>
`}</code></pre>
    <p>{`In multiple sources, I was found examples of where to the `}<inlineCode parentName="p">{`/public`}</inlineCode>{` folder was added `}<inlineCode parentName="p">{`color.less`}</inlineCode>{`, I cannot reproduce this examples, there were some mistakes, I assume it's a pretty old approach.`}</p>
    <p>{`That's all now you have antd with ability to configure theming dynamically and improve you user experience.`}</p>
    <h3>{`References:`}</h3>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://github.com/mzohaibqc/antd-theme-webpack-plugin"
        }}>{`https://github.com/mzohaibqc/antd-theme-webpack-plugin`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://ant.design/docs/react/customize-theme"
        }}>{`https://ant.design/docs/react/customize-theme`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://github.com/ant-design/ant-design-pro/blob/56e648ec14bdb9f6724169fd64830447e224ccb1/config/config.js#L45"
        }}>{`https://github.com/ant-design/ant-design-pro/blob/56e648ec14bdb9f6724169fd64830447e224ccb1/config/config.js#L45`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://github.com/arackaf/customize-cra"
        }}>{`https://github.com/arackaf/customize-cra`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://medium.com/@mzohaib.qc/ant-design-dynamic-runtime-theme-1f9a1a030ba0"
        }}>{`https://medium.com/@mzohaib.qc/ant-design-dynamic-runtime-theme-1f9a1a030ba0`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://github.com/YumcoderCom/antd-them-switch-cra"
        }}>{`https://github.com/YumcoderCom/antd-them-switch-cra`}</a></li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      